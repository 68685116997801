import { useState, useEffect, useRef } from "react";
import TimeAgo from "javascript-time-ago";
import Carousel from "react-material-ui-carousel";
import DriveEtaIcon from "@mui/icons-material/DriveEta";
import DirectionsBusIcon from "@mui/icons-material/DirectionsBus";
import { Card, Paper } from "@mui/material";
import en from "javascript-time-ago/locale/en";

TimeAgo.addDefaultLocale(en);

const timeAgo = new TimeAgo("en-US");

function Item(props: any) {
  const heightRef = useRef("225px");
  return (
    <Paper style={{ height: heightRef.current }}>
      <a href={props.href} target="_blank" rel="noreferrer">
        <div className="img-gradient" style={{ height: heightRef.current }}>
          <img
            className="img-container"
            alt={props.desc}
            src={props.image}
            style={{ height: heightRef.current }}
            onLoad={(image: any) => {
              heightRef.current = `${
                (image.target.height / image.target.width) * 300
              }px`;
            }}
          />
        </div>
      </a>
    </Paper>
  );
}

export default function PopupBox(props: any) {
  const [images, setImages] = useState<Array<string>>([]);
  useEffect(() => {
    let images = JSON.parse(props.apaJSON.images.replaceAll("'", '"'));
    if (images.length === 0) {
      images.push(
        "https://upload.wikimedia.org/wikipedia/commons/thumb/1/15/No_image_available_600_x_450.svg/640px-No_image_available_600_x_450.svg.png"
      );
    }
    setImages(images);
  }, [props.apaJSON.images]);
  return (
    <Card className="img-card">
      <Carousel
        className="img-carousel"
        animation="slide"
        autoPlay={false}
        swipe={false}
        navButtonsAlwaysVisible={true}
        indicators={false}
      >
        {images.map((image: string, idx: number) => (
          <Item
            desc={props.apaJSON.text_idx}
            key={image}
            href={props.apaJSON.cl_addr}
            image={image}
            idx={idx}
          />
        ))}
      </Carousel>
      <div className="img-bottom-left">
        {props.apaJSON.price ? (
          <div className="img-price">
            {"$" +
              parseInt(props.apaJSON.price).toLocaleString("en-US") +
              " / mo"}
          </div>
        ) : (
          <div className="img-addr-street">Price not available</div>
        )}
        <div className="img-posted">
          Posted {timeAgo.format(Date.parse(props.apaJSON.cl_posted + "Z"))}
        </div>
        {props.apaJSON.addr_street ? (
          <div className="img-addr-street">{props.apaJSON.addr_street}</div>
        ) : (
          <div className="img-addr-street">Address not available</div>
        )}
        {props.apaJSON.addr_locality ? (
          <div className="img-addr-locality">
            {props.apaJSON.addr_locality.toUpperCase() +
              ", " +
              props.apaJSON.addr_region +
              " " +
              props.apaJSON.addr_zipcode}
          </div>
        ) : (
          <div className="img-addr-locality">Locality not available</div>
        )}
      </div>
      <div className="img-bottom-right">
        {props.apaJSON.dist !== undefined && (
          <div className="img-dist">
            {props.method.current === "transit" ? (
              <DirectionsBusIcon
                fontSize="small"
                style={{ color: "white" }}
              ></DirectionsBusIcon>
            ) : (
              <DriveEtaIcon
                fontSize="small"
                style={{ color: "white" }}
              ></DriveEtaIcon>
            )}
            {Math.ceil(parseInt(props.apaJSON.dist) / 60).toString()} min
          </div>
        )}
        {props.apaJSON.sqft !== "0" && (
          <div className="img-sqft">
            {parseInt(props.apaJSON.sqft).toString()} ft
            <span style={{ verticalAlign: "super" }}>2</span>
          </div>
        )}
        <div className="img-bedroom">
          {parseInt(props.apaJSON.bedrooms).toString() + " BED "}
        </div>
        <div className="img-bathroom">
          {parseInt(props.apaJSON.bathrooms).toString() + " BATH"}
        </div>
      </div>
    </Card>
  );
}
