export function responsiveCols(drawerType: string, windowWidth: number) {
  if (
    (drawerType === "temporary" &&
      windowWidth < parseInt(process.env.REACT_APP_TEMPORARY_WIDTH!)) ||
    (drawerType === "permanent" &&
      windowWidth < parseInt(process.env.REACT_APP_PERMENANT_WIDTH!))
  ) {
    return 1;
  } else {
    return 2;
  }
}
