import { CssBaseline } from "@mui/material";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";

import Places from "./Places";
import Home from "./pages/Home";
import { theme } from "./assets/theme";

export default function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/places/" element={<Places />} />
            <Route path="/places/:place/" element={<Places />} />
          </Routes>
        </Router>
      </ThemeProvider>
    </div>
  );
}
