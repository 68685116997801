import * as React from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import parse from "autosuggest-highlight/parse";
import throttle from "lodash/throttle";

function loadScript(src: any, position: any, id: any) {
  if (!position) {
    return;
  }

  const script = document.createElement("script");
  script.setAttribute("async", "");
  script.setAttribute("id", id);
  script.src = src;
  position.appendChild(script);
}
let autocompleteService: any = null;

export default function AddressAutocomplete(props: any) {
  const [value, setValue] = React.useState<any>(null);
  const [inputValue, setInputValue] = React.useState<string>(
    props.address.current
  );
  const [options, setOptions] = React.useState<any>([]);
  const loaded = React.useRef(false);

  if (typeof window !== "undefined" && !loaded.current) {
    if (!document.querySelector("#google-maps")) {
      loadScript(
        `https://maps.googleapis.com/maps/api/js?` +
          new URLSearchParams({
            libraries: "places",
            key: process.env.REACT_APP_GOOGLE_MAP_APIKEY
              ? process.env.REACT_APP_GOOGLE_MAP_APIKEY
              : "",
            callback: "Function.prototype",
          }),
        document.querySelector("head"),
        "google-maps"
      );
    }

    loaded.current = true;
  }

  const fetch = React.useMemo(
    () =>
      throttle((request: any, callback: any) => {
        autocompleteService.getPlacePredictions(request, callback);
      }, 200),
    []
  );

  React.useEffect(() => {
    let active = true;

    if (!autocompleteService && window.google) {
      autocompleteService = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService) {
      return undefined;
    }

    if (inputValue === "") {
      setOptions(value ? [value] : []);
      return undefined;
    }

    fetch({ input: inputValue }, (results: any) => {
      if (active) {
        let newOptions: any = [];

        if (value) {
          newOptions = [value];
        }
        if (results) {
          newOptions = [...newOptions, ...results];
        }
        props.address.current = inputValue;

        setOptions(newOptions);
      }
    });

    return () => {
      active = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, inputValue, fetch]);

  return (
    <Autocomplete
      id="google-map-demo"
      sx={{ width: props.autoWidth ? undefined : 300 }}
      getOptionLabel={(option) =>
        typeof option === "string" ? option : option["description"]
      }
      componentsProps={{ popper: { style: { width: "fit-content" } } }}
      filterOptions={(x) => x}
      options={options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      onChange={(event, newValue) => {
        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        if (newValue !== null) {
          props.callback(props, newValue);
        }
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Address, city or zip code"
          value={props.address.current}
          fullWidth
        />
      )}
      renderOption={(props, option) => {
        const matches: any =
          option["structured_formatting"]["main_text_matched_substrings"];
        const parts = parse(
          option["structured_formatting"]["main_text"],
          matches.map((match: any) => [
            match.offset,
            match.offset + match.length,
          ])
        );

        return (
          <li {...props}>
            <Grid container alignItems="center">
              <Grid item>
                <Box
                  component={LocationOnIcon}
                  sx={{ color: "text.secondary", mr: 2 }}
                />
              </Grid>
              <Grid item xs>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{
                      fontWeight: part.highlight ? 700 : 400,
                    }}
                  >
                    {part.text}
                  </span>
                ))}

                <Typography variant="body2" color="text.secondary">
                  {option["structured_formatting"]["secondary_text"]}
                </Typography>
              </Grid>
            </Grid>
          </li>
        );
      }}
    />
  );
}
