import maplibregl from "maplibre-gl";
import Pbf from "pbf";
import geobuf from "geobuf";
import B64ToArrayBuffer from "../string/B64ToArrayBuffer";

export const removeGeoJSON = function (
  map: any,
  sources: any,
  layers: any,
  marker: any,
  setSources: any,
  setLayers: any,
  setMarker: any
) {
  if (layers.length !== 0) {
    for (let layer of layers) {
      map.removeLayer(layer);
    }
  }
  setLayers([]);
  if (sources.length !== 0) {
    for (let source of sources) {
      map.removeSource(source);
    }
  }
  setSources([]);
  if (marker !== null) {
    marker.remove();
  }
  setMarker(null);
};

export default function renderGeoJSON(
  isocontour: any,
  source: any,
  map: any,
  setLegends: any,
  setSources: any,
  setLayers: any,
  setMarker: any
) {
  let newLegends: any = {};
  let newSources = [];
  let newLayers = [];
  let sorted = Object.keys(isocontour);
  sorted.sort((a: any, b: any) => parseInt(a) - parseInt(b)).reverse();
  for (let timeCost of sorted) {
    for (let idx in isocontour[timeCost]) {
      var decoded: any = geobuf.decode(
        new Pbf(B64ToArrayBuffer(isocontour[timeCost][idx]))
      );
      map.addSource("isocontour-" + timeCost + "-" + idx, {
        type: "geojson",
        data: decoded,
      });
      newSources.push("isocontour-" + timeCost + "-" + idx);
      const layerProperties = decoded["properties"];
      newLegends[timeCost] = layerProperties["fill"];
      map.addLayer({
        id: "isocontour-layer-fill-" + timeCost + "-" + idx,
        type: "fill",
        source: "isocontour-" + timeCost + "-" + idx,
        paint: {
          "fill-color": layerProperties["fill"],
          "fill-opacity": layerProperties["fill-opacity"],
        },
      });
      newLayers.push("isocontour-layer-fill-" + timeCost + "-" + idx);
      map.addLayer({
        id: "isocontour-layer-outline-" + timeCost + "-" + idx,
        type: "line",
        source: "isocontour-" + timeCost + "-" + idx,
        paint: {
          "line-color": layerProperties["stroke"],
          "line-width": layerProperties["stroke-width"],
        },
      });
      newLayers.push("isocontour-layer-outline-" + timeCost + "-" + idx);
    }
  }
  const newMarker = new maplibregl.Marker({
    color: "#D0312D",
  })
    .setLngLat(source["coordinates"])
    .addTo(map);
  setLegends(newLegends);
  setSources(newSources);
  setLayers(newLayers);
  setMarker(newMarker);
  return map;
}
