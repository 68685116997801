import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
} from "@mui/material";
import AddressAutocomplete from "../AddressAutocomplete";
import "./SearchBox.css";

export const SearchBox = (props: any) => {
  return (
    <form>
      <Card variant="outlined" className="SearchBoxCard">
        <CardHeader
          className="SearchBoxCardHeader"
          title="Find the best apartment for your commute."
          titleTypographyProps={{ variant: "h4" }}
        />
        <CardContent className="SearchBoxCardContent">
          <AddressAutocomplete
            address={props.address}
            setAlert={props.setAlert}
            setAlertContent={props.setAlertContent}
            callback={props.callback}
            autoWidth={true}
          />
        </CardContent>
        <CardActions className="SearchBoxCardAction">
          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="SearchBoxButton"
            onClick={props.onClick}
          >
            Search
          </Button>
        </CardActions>
      </Card>
    </form>
  );
};
