import { Waypoint } from "react-waypoint";
import { isMobile } from "react-device-detect";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import PopupBox from "../components/PopupBox";
import useWindowDimensions from "../commons/window/useWindowDimensions";
import { responsiveCols } from "../commons/window/responsiveCols";

export default function ApartmentsList(props: any) {
  let doubleCol = 630;
  let singleCol = 330;
  if (isMobile) {
    doubleCol = 615;
    singleCol = 315;
  }
  let columns = 2;
  let listWidth = doubleCol;
  let windowWidth = useWindowDimensions().width;
  if (responsiveCols(props.drawerType, windowWidth) === 1) {
    columns = 1;
    listWidth = singleCol;
  }
  props.apaJSONList.map((x: any) => {
    return (x.dist = props.dist[x.cl_id]);
  });
  if (props.sortCol !== "cl_posted") {
    props.apaJSONList.sort(
      (a: any, b: any) =>
        parseInt(a[props.sortCol]) - parseInt(b[props.sortCol])
    );
  } else {
    props.apaJSONList.sort(
      (a: any, b: any) =>
        Date.parse(a[props.sortCol]) - Date.parse(b[props.sortCol])
    );
  }
  if (
    (props.sortCol === "price" && props.priceSort === "desc") ||
    (props.sortCol === "cl_posted" && props.postedSort === "desc") ||
    (props.sortCol === "dist" && props.distSort === "desc") ||
    (props.sortCol === "sqft" && props.sqftSort === "desc")
  ) {
    props.apaJSONList.reverse();
  }
  let apaJSONList1 = props.apaJSONList;
  let apaJSONList2 = props.apaJSONList;
  if (columns === 2) {
    apaJSONList1 = props.apaJSONList.filter(
      (element: any, index: number) => index % 2 === 0
    );
    apaJSONList2 = props.apaJSONList.filter(
      (element: any, index: number) => index % 2 === 1
    );
  }
  return (
    <Box className="apa-list" sx={{ width: listWidth }} role="presentation">
      <div
        style={{
          display: "flex",
          alignItems: "start",
        }}
      >
        <List
          style={{
            width: 300,
            maxHeight: "100%",
            flexFlow: "column wrap",
            overflow: "auto",
          }}
        >
          {apaJSONList1.map((apaJSON: any) => (
            <div key={apaJSON.cl_id} className="mt6 mb6">
              <PopupBox
                apaJSON={apaJSON}
                sx={{ width: 300 }}
                method={props.method}
              />
            </div>
          ))}
        </List>
        {columns === 2 && (
          <List
            style={{
              width: 300,
              maxHeight: "100%",
              flexFlow: "column wrap",
              overflow: "auto",
              paddingLeft: "5px",
            }}
          >
            {apaJSONList2.map((apaJSON: any) => (
              <div key={apaJSON.cl_id} className="mt6 mb6">
                <PopupBox
                  apaJSON={apaJSON}
                  sx={{ width: 300 }}
                  method={props.method}
                />
              </div>
            ))}
          </List>
        )}
      </div>
      <Waypoint
        onEnter={() => {
          if (!props.loaded) {
            props.setLoaded(true);
          } else {
            props.loadMore();
          }
        }}
      />
      <Divider />
    </Box>
  );
}
