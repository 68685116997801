import { useState } from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Slider from "@mui/material/Slider";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";

export interface FilterDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

export default function FilterDialog(props: any) {
  const { onClose, selectedValue, open } = props;
  const [type, setType] = useState("apa");
  const [bedroom, setBedroom] = useState<string[]>([]);
  const [bathroom, setBathroom] = useState<string[]>([]);
  const [imagesOnly, setImagesOnly] = useState<string>("");
  const [amenities1, setAmenities1] = useState<string[]>([]);
  const [amenities2, setAmenities2] = useState<string[]>([]);
  const [amenities3, setAmenities3] = useState<string[]>([]);
  const [amenities4, setAmenities4] = useState<string[]>([]);
  const [price, setPrice] = useState<number[]>([
    0,
    parseInt(process.env.REACT_APP_HISTOGRAM_MAX!),
  ]);
  const [sqft, setSqft] = useState<number[]>([0, 2000]);

  const handleClose = () => {
    onClose(selectedValue);
  };

  function priceText(price: number, index: number) {
    if (price < parseInt(process.env.REACT_APP_HISTOGRAM_MAX!)) {
      return `$${price}`;
    } else {
      return `$${price}+`;
    }
  }

  function sqftText(sqft: number, index: number) {
    if (sqft < 2000) {
      return `${sqft}`;
    } else {
      return `${sqft}+`;
    }
  }

  const handleChangePrice = (event: Event, newPrice: number | number[]) => {
    setPrice(newPrice as number[]);
  };

  const handleChangeSqft = (event: Event, newSqft: number | number[]) => {
    setSqft(newSqft as number[]);
  };

  const handleChangeType = (event: any, newType: string) => {
    if (newType !== null) {
      setType(newType);
    }
  };

  const handleChangeImagesOnly = (event: any, newImagesOnly: string) => {
    setImagesOnly(newImagesOnly);
  };

  const handleChangeBedroom = (event: any, newBedroom: string[]) => {
    if (newBedroom !== null) {
      setBedroom(newBedroom);
    }
  };

  const handleChangeBathroom = (event: any, newBathroom: string[]) => {
    if (newBathroom !== null) {
      setBathroom(newBathroom);
    }
  };

  const handleChangeAmenities1 = (event: any, newAmenities1: string[]) => {
    if (newAmenities1 !== null) {
      setAmenities1(newAmenities1);
    }
  };

  const handleChangeAmenities2 = (event: any, newAmenities2: string[]) => {
    if (newAmenities2 !== null) {
      setAmenities2(newAmenities2);
    }
  };

  const handleChangeAmenities3 = (event: any, newAmenities3: string[]) => {
    if (newAmenities3 !== null) {
      setAmenities3(newAmenities3);
    }
  };

  const handleChangeAmenities4 = (event: any, newAmenities4: string[]) => {
    if (newAmenities4 !== null) {
      setAmenities4(newAmenities4);
    }
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <div className="filter-dialog">
        <DialogTitle>Filter</DialogTitle>
        <div className="ml12 mr12 mb12">
          <ToggleButtonGroup
            value={type}
            exclusive
            onChange={handleChangeType}
            aria-label="Type"
          >
            <ToggleButton value="apa">For Rent</ToggleButton>
            <ToggleButton value="rea" disabled>
              For Sale
            </ToggleButton>
            <ToggleButton value="roo">For Room</ToggleButton>
          </ToggleButtonGroup>
          <div className="mt6 mb6">
            <h3 className="txt-bold txt-m block">
              Price range: {priceText(price[0], 0)} - {priceText(price[1], 1)}
            </h3>
          </div>
          <div className="ml6 mr24 mb6">
            <Slider
              getAriaLabel={() => "Price range"}
              value={price}
              onChange={handleChangePrice}
              valueLabelDisplay="auto"
              valueLabelFormat={priceText}
              step={200}
              marks
              min={0}
              max={parseInt(process.env.REACT_APP_HISTOGRAM_MAX!)}
              getAriaValueText={priceText}
            />
          </div>
          <div className="mt6 mb6">
            <h3 className="txt-bold txt-m block">
              Square feet: {sqftText(sqft[0], 0)} ft
              <span style={{ verticalAlign: "super" }}>2</span> -{" "}
              {sqftText(sqft[1], 1)} ft
              <span style={{ verticalAlign: "super" }}>2</span>
            </h3>
          </div>
          <div className="ml6 mr24 mb6">
            <Slider
              getAriaLabel={() => "Sq ft range"}
              value={sqft}
              onChange={handleChangeSqft}
              valueLabelDisplay="auto"
              valueLabelFormat={sqftText}
              step={100}
              marks
              min={0}
              max={2000}
              getAriaValueText={sqftText}
            />
          </div>
          <ToggleButtonGroup
            value={imagesOnly}
            exclusive
            onChange={handleChangeImagesOnly}
            aria-label="Type"
          >
            <ToggleButton value="true">Images Only</ToggleButton>
          </ToggleButtonGroup>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <ToggleButtonGroup
              value={bedroom}
              onChange={handleChangeBedroom}
              aria-label="Type"
            >
              <ToggleButton value="0">0</ToggleButton>
              <ToggleButton value="1">1</ToggleButton>
              <ToggleButton value="2">2</ToggleButton>
              <ToggleButton value="3">3</ToggleButton>
              <ToggleButton value="4">4</ToggleButton>
              <ToggleButton value="5+">5+</ToggleButton>
            </ToggleButtonGroup>
            <Typography noWrap className="ml6 txt-m">
              Bedrooms
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <ToggleButtonGroup
              value={bathroom}
              onChange={handleChangeBathroom}
              aria-label="Type"
            >
              <ToggleButton value="0">0</ToggleButton>
              <ToggleButton value="1">1</ToggleButton>
              <ToggleButton value="2">2</ToggleButton>
              <ToggleButton value="3">3</ToggleButton>
              <ToggleButton value="4">4</ToggleButton>
              <ToggleButton value="5+">5+</ToggleButton>
            </ToggleButtonGroup>
            <Typography noWrap className="ml6 txt-m">
              Bathrooms
            </Typography>
          </div>
          <div className="mt6 mb6">
            <h3 className="txt-bold txt-m block">Features & Amenities</h3>
          </div>
          <div>
            <ToggleButtonGroup
              value={amenities1}
              onChange={handleChangeAmenities1}
              aria-label="Type"
            >
              <ToggleButton value="cats">Cats OK</ToggleButton>
              <ToggleButton value="dogs">Dogs OK</ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div>
            <ToggleButtonGroup
              value={amenities2}
              onChange={handleChangeAmenities2}
              aria-label="Type"
            >
              <ToggleButton value="wheelchair">
                Wheelchair Accessible
              </ToggleButton>
              <ToggleButton value="no_smoking">No Smoking</ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div>
            <ToggleButtonGroup
              value={amenities3}
              onChange={handleChangeAmenities3}
              aria-label="Type"
            >
              <ToggleButton value="furnished">Furnished</ToggleButton>
              <ToggleButton value="ac">Air Conditioning</ToggleButton>
            </ToggleButtonGroup>
          </div>
          <div>
            <ToggleButtonGroup
              value={amenities4}
              onChange={handleChangeAmenities4}
              aria-label="Type"
            >
              <ToggleButton value="ev">EV Charging</ToggleButton>
              <ToggleButton value="laundry">Laundry</ToggleButton>
              <ToggleButton value="parking">Parking</ToggleButton>
            </ToggleButtonGroup>
          </div>
          <Button
            variant="contained"
            onClick={() => {
              onClose();
              props
                .onClick(
                  type,
                  imagesOnly,
                  bedroom,
                  bathroom,
                  [
                    ...amenities1,
                    ...amenities2,
                    ...amenities3,
                    ...amenities4,
                  ].join(","),
                  price,
                  sqft
                )
                .then(() => {})
                .catch((error: any) => {
                  props.setAlert(true);
                  props.setAlertContent({
                    original: error.message,
                    show: error.message,
                  });
                });
            }}
          >
            Search
          </Button>
        </div>
      </div>
    </Dialog>
  );
}
