import maplibregl from "maplibre-gl";
import { createRoot } from "react-dom/client";

import fetchJSON from "../../commons/fetch/FetchJSON";
import PopupBox from "../../components/PopupBox";

const onClickPoint = (
  map: any,
  dist: any,
  method: any,
  setAlert: any,
  setAlertContent: any
) => {
  // When a click event occurs on a feature in
  // the unclustered-point layer, open a popup at
  // the location of the feature, with
  // description HTML from its properties.
  return (e: any) => {
    const coordinates = e.features[0].geometry.coordinates.slice();
    const cl_id = e.features[0].properties.cl_id;
    // Ensure that if the map is zoomed out such that
    // multiple copies of the feature are visible, the
    // popup appears over the copy being pointed to.
    while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
      coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
    }
    fetchJSON(
      `${process.env.REACT_APP_BRUSSELS_HOST}/crawler/?` +
        new URLSearchParams({
          cl_ids: cl_id,
          cl_dept: "apa",
        }),
      setAlert,
      setAlertContent
    )
      .then((apaJSON: any) => {
        apaJSON.map((x: any) => (x.dist = dist[x.cl_id]));
        const popupNode = document.createElement("div");
        const root = createRoot(popupNode);
        root.render(<PopupBox apaJSON={apaJSON[0]} method={method} />);
        new maplibregl.Popup({
          closeButton: false,
          maxWidth: "310px",
        })
          .setLngLat(coordinates)
          .setDOMContent(popupNode)
          .addTo(map);
      })
      .catch((error) => {
        setAlert(true);
        setAlertContent({
          original: error.message,
          show: "Network Error!",
        });
      });
  };
};

let markerCluster = function (
  listings: any,
  map: any,
  setAlert: any,
  setAlertContent: any,
  clusterLoadedRef: any,
  dist: any,
  method: any,
  oldFuncRef: any
) {
  var mapLayer = map.getLayer("clusters");
  if (typeof mapLayer !== "undefined") {
    // Remove map layer & source.
    map.removeLayer("clusters");
    map.removeLayer("cluster-count");
    map.removeLayer("unclustered-point");
    map.removeSource("listings");
  }
  map.addSource("listings", {
    type: "geojson",
    data: listings,
    cluster: true,
    clusterMaxZoom: 14, // Max zoom to cluster points on
    clusterRadius: 30, // Radius of each cluster when clustering points (defaults to 50)
  });
  map.addLayer({
    id: "clusters",
    type: "circle",
    source: "listings",
    filter: ["has", "point_count"],
    paint: {
      "circle-color": [
        "step",
        ["get", "point_count"],
        "#83acd2",
        200,
        "#83acd2",
      ],
      "circle-radius": [
        "step",
        ["get", "point_count"],
        14,
        20,
        16,
        50,
        18,
        200,
        20,
        500,
        24,
        2000,
        28,
        5000,
        32,
      ],
      "circle-stroke-width": 2,
      "circle-stroke-color": "#335c82",
    },
  });
  map.addLayer({
    id: "cluster-count",
    type: "symbol",
    source: "listings",
    filter: ["has", "point_count"],
    layout: {
      "text-field": ["get", "point_count_abbreviated"],
      "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
      "text-size": 12,
    },
  });
  map.addLayer({
    id: "unclustered-point",
    type: "circle",
    source: "listings",
    filter: ["!", ["has", "point_count"]],
    paint: {
      "circle-color": "#83acd2",
      "circle-radius": 9,
      "circle-stroke-width": 2,
      "circle-stroke-color": "#335c82",
    },
  });
  if (!clusterLoadedRef.current) {
    // inspect a cluster on click
    map.on("click", "clusters", (e: any) => {
      const features = map.queryRenderedFeatures(e.point, {
        layers: ["clusters"],
      });
      const clusterId = features[0].properties.cluster_id;
      map
        .getSource("listings")
        .getClusterExpansionZoom(clusterId, (err: any, zoom: any) => {
          if (err) return;
          map.easeTo({
            center: features[0].geometry.coordinates,
            zoom: zoom,
          });
        });
    });
    // 保存初态函数
    oldFuncRef.current = onClickPoint(
      map,
      dist,
      method,
      setAlert,
      setAlertContent
    );
    map.on("click", "unclustered-point", oldFuncRef.current);
    map.on("mouseenter", "clusters", () => {
      map.getCanvas().style.cursor = "pointer";
    });
    map.on("mouseleave", "clusters", () => {
      map.getCanvas().style.cursor = "";
    });
    clusterLoadedRef.current = true;
  }
  // 刷新旧函数
  if (Object.keys(dist).length > 0) {
    map.off("click", "unclustered-point", oldFuncRef.current);
    oldFuncRef.current = onClickPoint(
      map,
      dist,
      method,
      setAlert,
      setAlertContent
    );
    map.on("click", "unclustered-point", oldFuncRef.current);
  }
  return map;
};

export default markerCluster;
