let fetchJSON = function fetchJSON(
  url: string,
  setAlert: any,
  setAlertContent: any
) {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then(function (response) {
        setAlert(false);
        resolve(response.json());
      })
      .catch((error) => {
        setAlert(true);
        setAlertContent({
          original: error.message,
          show: "Network error!",
        });
      });
  });
};

export default fetchJSON;
