import Box from "@mui/material/Box";
import { isMobile } from "react-device-detect";
import { responsiveCols } from "../commons/window/responsiveCols";
import useWindowDimensions from "../commons/window/useWindowDimensions";

const Legend = (props: any) => {
  const renderLegendKeys = (legend: Array<any>, i: number) => {
    return (
      <div key={i} className="txt-s align-l">
        <span
          className="mr6 round-full w12 h12 inline-block align-middle"
          style={{ backgroundColor: legend[1] }}
        />
        <span>{`${legend[0] / 60} min`}</span>
      </div>
    );
  };
  let ml = 0;
  let windowWidth = useWindowDimensions().width;
  if (
    windowWidth >
    parseInt(process.env.REACT_APP_TEMPORARY_WIDTH!)
  ) {
    if (responsiveCols("permanent", windowWidth) === 2) {
      ml = 630;
      if (isMobile) {
        ml = 615;
      }
    } else {
      ml = 330;
      if (isMobile) {
        ml = 315;
      }
    }
  } else {
    ml = 0;
  }

  if (!!props.legends) {
    return (
      <Box
        className="legends bg-white absolute bottom left mb12 py12 px12 shadow-darken10 round z1 wmax120"
        sx={{
          ml: `${ml + 12}px`,
        }}
      >
        <div className="mb6">
          <h2 className="txt-bold txt-s block align-l">Isocontour</h2>
          <p className="txt-s color-gray align-l">
            Reachable areas by transit and walk within
          </p>
        </div>
        {Object.entries(props.legends).map(renderLegendKeys)}
      </Box>
    );
  } else {
    return null;
  }
};

export default Legend;
