import { Divider, Link, Typography } from "@mui/material";
import CopyrightIcon from "@mui/icons-material/Copyright";
import "./Footer.css";

export const Footer = () => {
  return (
    <footer>
      <div className="FooterSection">
        <Divider variant="middle"></Divider>
        <div className="FooterContainer">
          <div className="FooterItemContainer">
            <CopyrightIcon fontSize="small" />
            <Typography color="textSecondary" variant="body2">
              <Link href="/">HomeEazy</Link>
            </Typography>
          </div>

          <Divider orientation="vertical" flexItem></Divider>
          <div className="FooterItemContainer">
            <Typography color="textSecondary" variant="body2">
              <Link href="mailto:vincentju93@gmail.com">Contact</Link>
            </Typography>
          </div>

          <Divider orientation="vertical" flexItem></Divider>
          <div className="FooterItemContainer">
            <Typography color="textSecondary" variant="body2">
              <Link href="https://github.com/homeeazy/issue-tracker/issues">
                Submit an Issue
              </Link>
            </Typography>
          </div>

          <Divider orientation="vertical" flexItem></Divider>
          <div className="FooterItemContainer">
            <Typography color="textSecondary" variant="body2">
              <Link href="sitemap.xml">Sitemap</Link>
            </Typography>
          </div>
        </div>
      </div>
    </footer>
  );
};
