import { useRef, useState } from "react";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import Dialog from "@mui/material/Dialog";
import Slider from "@mui/material/Slider";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import AddressAutocomplete from "../AddressAutocomplete";
import fetchLocation from "../../commons/fetch/FetchLocation";

function valuetext(value: any) {
  return `${value} min`;
}

export interface CommuteDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}

export default function CommuteDialog(props: any) {
  const { onClose, selectedValue, open } = props;
  const [method, setMethod] = useState("transit");
  const [fromTo, setFromTo] = useState("to");
  const description = useRef("");
  const [timeLimit, setTimeLimit] = useState(
    parseInt(
      method === "transit"
        ? process.env.REACT_APP_LIMIT_TRANSIT_MIN!
        : process.env.REACT_APP_LIMIT_DRIVE_MIN!
    )
  );

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleChangeMethod = (event: any, newMethod: any) => {
    if (newMethod !== null) {
      setMethod(newMethod);
    }
  };

  const handleChangeFromTo = (event: any, newFromTo: any) => {
    if (newFromTo !== null) {
      setFromTo(newFromTo);
    }
  };

  let addressAutocompleteCallback = (props: any, newValue: any) => {
    fetchLocation(
      newValue["description"],
      props.setAlert,
      props.setAlertContent
    )
      .then((respJSON: any) => {
        description.current = newValue["description"];
      })
      .catch((error) => {
        props.setAlert(true);
        props.setAlertContent({
          original: error.message,
          show: "Place not found!",
        });
      });
  };

  return (
    <Dialog onClose={handleClose} open={open}>
      <div className="commute-dialog">
        <DialogTitle>Search by Commute</DialogTitle>
        <div className="ml12 mr12 mb12">
          <div className="mb12">
            <h2 className="txt-bold txt-m block">Commute destination:</h2>
          </div>
          <ToggleButtonGroup
            value={fromTo}
            exclusive
            onChange={handleChangeFromTo}
            aria-label="Platform"
          >
            <ToggleButton value="from">From</ToggleButton>
            <ToggleButton value="to">To</ToggleButton>
          </ToggleButtonGroup>
          <AddressAutocomplete
            address={props.address}
            setAlert={props.setAlert}
            setAlertContent={props.setAlertContent}
            callback={addressAutocompleteCallback}
          />
          <ToggleButtonGroup
            value={method}
            exclusive
            onChange={handleChangeMethod}
            aria-label="Platform"
          >
            <ToggleButton value="transit">Transit</ToggleButton>
            <ToggleButton value="drive">Drive</ToggleButton>
          </ToggleButtonGroup>
          <div className="mt6 mb6">
            <h2 className="txt-bold txt-m block">
              Commute time limit: {timeLimit} min
            </h2>
          </div>
          <div className="mr12">
            <Slider
              aria-label="Time limit"
              value={timeLimit}
              getAriaValueText={valuetext}
              valueLabelDisplay="auto"
              step={parseInt(
                method === "transit"
                  ? process.env.REACT_APP_LIMIT_TRANSIT_STEP!
                  : process.env.REACT_APP_LIMIT_DRIVE_STEP!
              )}
              marks
              min={parseInt(
                method === "transit"
                  ? process.env.REACT_APP_LIMIT_TRANSIT_MIN!
                  : process.env.REACT_APP_LIMIT_DRIVE_MIN!
              )}
              max={parseInt(
                method === "transit"
                  ? process.env.REACT_APP_LIMIT_TRANSIT_MAX!
                  : process.env.REACT_APP_LIMIT_DRIVE_MAX!
              )}
              onChange={(props: any) => {
                setTimeLimit(props.target.value);
              }}
            />
          </div>
          <Button
            variant="contained"
            onClick={() => {
              fetchLocation(
                props.address.current,
                props.setAlert,
                props.setAlertContent
              )
                .then((respJSON: any) => {
                  if (
                    method === "drive" &&
                    respJSON["state_province"] !== "California"
                  ) {
                    throw new Error(
                      "INRIX traffic data is only available for California"
                    );
                  }
                  props.onClick(
                    respJSON["geometry"]["location"],
                    respJSON["region"],
                    timeLimit,
                    fromTo,
                    method,
                    description.current
                  );
                  onClose();
                })
                .catch((error) => {
                  props.setAlert(true);
                  props.setAlertContent({
                    original: error.message,
                    show: error.message,
                  });
                });
            }}
          >
            Search
          </Button>
          <div className="mt12">
            <h2 className="txt-bold txt-m block">Hint</h2>
            <p className="txt-m w300 color-gray">
              Enter the address of your work/school, and desired one-way commute
              time cost.
            </p>
            <p className="txt-m w300 color-gray">
              We'll render the reachable areas by transit or drive at 8am for
              weekdays.
            </p>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
