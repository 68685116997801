import Typography from "@mui/material/Typography";
import { useMediaQuery } from "@mui/material";
import { theme } from "../assets/theme";
export const title = "HomeEazy";

const Logo = (props: any) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  return (
    <div>
      <a href="/" target="_blank" rel="noreferrer">
        <img
          alt="HomeEazy logo"
          src={process.env.PUBLIC_URL + "/logo512.png"}
          style={{
            height: 32,
            width: 32,
          }}
        />
        <Typography
          variant={isSmallScreen ? "subtitle1" : "h6"}
          color="inherit"
          style={{ display: "inline-block" }}
          noWrap
          sx={{
            ml: 0.5,
          }}
        >
          {title}
        </Typography>
      </a>
    </div>
  );
};

export default Logo;
