import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

export default function SortLabel(props: any) {
  return (
    <div>
      {props.label}
      {props.sort === "desc" ? (
        <ArrowDownwardIcon></ArrowDownwardIcon>
      ) : (
        <ArrowUpwardIcon></ArrowUpwardIcon>
      )}
    </div>
  );
}
