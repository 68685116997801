import CanvasJSReact from "../../assets/CanvasJS/canvasjs.react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const genHist = (hist: Array<number>) => {
  let max = Math.min(
    parseInt(process.env.REACT_APP_HISTOGRAM_MAX!),
    Math.max(...hist)
  );
  let min = Math.min(...hist);
  let i = 1;
  while (true) {
    let buckets =
      (max - min) / (i * parseInt(process.env.REACT_APP_HISTOGRAM_STEP!));
    if (buckets >= 1 && buckets <= 30) {
      break;
    }
    i++;
  }
  let bucket = i * parseInt(process.env.REACT_APP_HISTOGRAM_STEP!);
  min = Math.floor(min / bucket) * bucket;
  let histogram: { [id: string]: number } = {};
  for (let val of hist) {
    let idx = Math.min(
      parseInt(process.env.REACT_APP_HISTOGRAM_MAX!),
      Math.floor(val / bucket) * bucket
    ).toString();
    if (!histogram.hasOwnProperty(idx)) {
      histogram[idx] = 0;
    }
    histogram[idx] += 1;
  }
  let ret = [];
  for (const [idx, value] of Object.entries(histogram)) {
    let beautyIdx = `$${idx} - ${parseInt(idx) + bucket}`;
    if (idx === process.env.REACT_APP_HISTOGRAM_MAX!) {
      beautyIdx = `$${process.env.REACT_APP_HISTOGRAM_MAX!}+`;
    }
    ret.push({
      label: beautyIdx,
      sort: parseInt(idx),
      y: value,
    });
  }
  ret.sort((a: any, b: any) => parseInt(a.sort) - parseInt(b.sort));
  return ret;
};

export default function HistogramDialog(props: any) {
  const { onClose, selectedValue, open } = props;
  let histogram: any[] = [];
  if (props.listings !== undefined) {
    let hist = props.listings.map((a: any) => a.properties.price);
    if (hist.length > 20) {
      histogram = genHist(hist);
    }
  }
  const options = {
    title: {
      text: "",
    },
    width: 320,
    data: [
      {
        type: "column",
        dataPoints: histogram,
      },
    ],
  };

  const handleClose = () => {
    onClose(selectedValue);
  };

  return (
    <Dialog
      onClose={handleClose}
      open={open}
      PaperProps={{
        sx: {
          width: "100%",
          maxWidth: "340px!important",
          height: "100%",
          maxHeight: "480px!important",
        },
      }}
    >
      <DialogTitle>Price Histogram</DialogTitle>
      <div>
        <CanvasJSChart options={options} />
      </div>
    </Dialog>
  );
}
