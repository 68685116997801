import { useState, useRef, useEffect } from "react";
import "../Places.css";
import maplibregl from "maplibre-gl";
import Box from "@mui/material/Box";
import fetchLocation from "../commons/fetch/FetchLocation";
import { findArea } from "../commons/ref/Area";

export default function Mapbox(props: any) {
  const [loaded, setLoaded] = useState(false);
  const [ourArea, setOurArea] = useState(process.env.REACT_APP_ORIGIN_REGION);
  const mapContainer = useRef<HTMLDivElement>(null);
  const placeLoaded = useRef(false);
  let setArea = (coord: any) => {
    let newArea = findArea(coord);
    if (props.ourArea.current !== newArea && newArea !== undefined) {
      props.ourArea.current = newArea;
      setOurArea(newArea);
    }
  };

  useEffect(() => {
    let map = new maplibregl.Map({
      container: mapContainer.current || "",
      style:
        `https://api.maptiler.com/maps/streets-v2/style.json?` +
        new URLSearchParams({
          key: process.env.REACT_APP_MAPTILES_TOKEN
            ? process.env.REACT_APP_MAPTILES_TOKEN
            : "",
        }),
      center: props.origin,
      zoom: props.zoom,
    });
    map.on("load", () => {
      if (loaded === false) {
        if (props.place !== undefined && !placeLoaded.current) {
          placeLoaded.current = false;
          fetchLocation(props.place, props.setAlert, props.setAlertContent)
            .then((respJSON: any) => {
              map.flyTo({
                center: respJSON["geometry"]["location"],
                zoom: 11,
              });
            })
            .catch((error) => {
              props.setAlert(true);
              props.setAlertContent({
                original: error.message,
                show: "Place not found!",
              });
            });
          return;
        }
        setLoaded(true);
      }
      let newMapBoundary = map.getBounds();
      setArea({
        lat: (newMapBoundary._ne.lat + newMapBoundary._sw.lat) / 2.0,
        lng: (newMapBoundary._ne.lng + newMapBoundary._sw.lng) / 2.0,
      });
    });
    map.on("moveend", () => {
      if (loaded === false) {
        setLoaded(true);
      }
      let newMapBoundary = map.getBounds();
      setArea({
        lat: (newMapBoundary._ne.lat + newMapBoundary._sw.lat) / 2.0,
        lng: (newMapBoundary._ne.lng + newMapBoundary._sw.lng) / 2.0,
      });
    });
    props.setMap(map);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.map !== null && props.isocontourRef.current === false) {
      props.query();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded, ourArea]);
  return (
    <Box
      ref={mapContainer}
      className="map-container"
      sx={{
        position: "absolute",
        top: 0,
        bottom: 0,
        left:
          props.windowWidth > process.env.REACT_APP_PERMENANT_WIDTH!
            ? "600px"
            : props.windowWidth > process.env.REACT_APP_TEMPORARY_WIDTH!
            ? "300px"
            : 0,
        right: 0,
      }}
    ></Box>
  );
}
