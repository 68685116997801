import { Button } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import AppToolbar from "@mui/material/Toolbar";
import GithubIcon from "@mui/icons-material/GitHub";
import { useNavigate } from "react-router-dom";
import Logo from "../../components/Logo";
import "./Toolbar.css";

export const Toolbar = () => {
  const navigate = useNavigate();

  return (
    <AppBar position="sticky" className="AppBar" color="inherit">
      <AppToolbar>
        <div className="HeaderContainer">
          <div className="HeaderLeftContainer" onClick={() => navigate("/")}>
            <Logo />
          </div>
          <div>
            <Button
              id="github-button"
              color="primary"
              onClick={() =>
                (window.location.href =
                  "https://github.com/homeeazy/issue-tracker/issues")
              }
            >
              <GithubIcon></GithubIcon>
            </Button>
          </div>
        </div>
      </AppToolbar>
    </AppBar>
);
};
