export const area: { [region: string]: any } = {
  sfbay: {
    _ne: { lat: 39, lng: -120 },
    _sw: { lat: 36, lng: -124 },
    name: "SF Bay Area",
  },
  losangeles: {
    _ne: { lat: 34.5, lng: -116.7 },
    _sw: { lat: 33.3, lng: -120 },
    name: "Los Angeles",
  },
  sandiego: {
    _ne: { lat: 33.3, lng: -116.5 },
    _sw: { lat: 32.53, lng: -117.6 },
    name: "San Diego",
  },
  seattle: {
    _ne: { lat: 49, lng: -121 },
    _sw: { lat: 46.5, lng: -126 },
    name: "Seattle",
  },
  boston: {
    _ne: { lat: 43.38, lng: -69 },
    _sw: { lat: 41.3, lng: -71.85 },
    name: "Boston",
  },
  newyork: {
    _ne: { lat: 41.9, lng: -71.85 },
    _sw: { lat: 39.5, lng: -76 },
    name: "New York",
  },
  washingtondc: {
    _ne: { lat: 39.5, lng: -76.1 },
    _sw: { lat: 38.5, lng: -77.7 },
    name: "Washington DC",
  },
};

export function findArea(coord: any) {
  for (const [newArea, latlng] of Object.entries(area)) {
    if (
      latlng._ne.lat > coord.lat &&
      coord.lat > latlng._sw.lat &&
      latlng._ne.lng > coord.lng &&
      coord.lng > latlng._sw.lng
    ) {
      return newArea;
    }
  }
}
